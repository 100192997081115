import React from "react";
import { AiOutlineInstagram, AiFillFacebook } from "react-icons/ai";

const Kontakt = () => {
  return (
    <div>
    <div className="container mx-auto flex flex-col md:flex-row justify-around pb-10 space-y-10 md:space-y-0">
      <div className="flex flex-1 flex-col mx-auto">
        <div className="text-3xl uppercase font-bold my-3">dane kontaktowe</div>
        <p>Marcin Mróz</p>
        <p>
          tel. <a href="tel:+48729371060">729 371 060</a>
        </p>
        <p>
          mail:{" "}
          <a href="mailto:info@upgradeplatform.pl">info@upgradeplatform.pl</a>
        </p>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="text-3xl uppercase font-bold mx-auto my-3">social</div>
        <div className="flex cursor-pointer text-4xl space-x-2 mx-auto">
          <a
            href="https://www.instagram.com/upgrade.platform"
            className="hover:scale-110 transition">
            <AiOutlineInstagram />
          </a>
          <a
            hreff="https://www.facebook.com/upgrade.platform"
            className="hover:scale-110 transition">
            <AiFillFacebook />
          </a>
        </div>
      </div>
    </div>
    <div className="bg-red-700 h-[10px] w-full"></div>
    <div className="bg-black h-[200px] w-full opacity-80 flex flex-col items-center justify-center">
      <p>&copy; Upgrade Platform {getYear()}</p>
      <p><a href="https://emer.co" className="opacity-50">emer.co</a></p>
      </div>
    
    </div>
  );
};

export default Kontakt;


function getYear() {
  const year = new Date().getFullYear();
  return year;
}