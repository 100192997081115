import React, { useState, useEffect, useMemo } from "react";
// import { Link } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";

const api =
  "https://graph.facebook.com/upgrade.platform/events?access_token=EAACox6Y8wpgBACuNpEcfUioQoBfSfpgIeax1SjyAJzj1O1nef2KLI7proAqmB20KMNrEsPfK41HHJHyn64o1UdXHsE0XTzv4IKVtoN14u8Tptn1JnepqAY8eV8zEFN7KGSjDoOQpwEjH2aMpfr4S6MazOfvaCUMmIWgDYQZDZD";

const FBevent = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const fechEvents = async () => {
    setLoading(true);
    try {
      const response = await fetch(api);
      const event = await response.json();
      setEvents(event.data);
      setLoading(false);      
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // useEffect(() => {
  //   console.log("pobieram API");
  //   //fechEvents();
  // }, []);

useMemo(()=>{
  fechEvents();
 },[]);

  if (loading) {
    return <div className="flex justify-center">Ładowanie...</div>;
  }
  else {
  return (
    <div className="container mx-auto">
      {events.map((e) => {
        // console.log(e.description)
        return (<Event key={e.id} {...e} />);
      })}
    </div>
  );
    }
};

export default FBevent;

const Event = ({ id, name, place, start_time, end_time, description }) => {
  const [currentData, setCurrentData] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const startTime = new Date(start_time);
  console.log(description)
  return (
    <>
      <div className="flex flex-col items-center space-y-2 pt-12 pb-6">
        <div className="flex text-2xl font-semibold">
          <div className="text-center">{name}</div>
        </div>
        <div className="flex font-light">
          {currentData > startTime ? (
            <div className="">zajęcia już się odbyły</div>
          ) : (
            <div className="">
              {start_time.slice(0, 10)}
              {end_time && " - " + end_time.slice(0, 10)}
              {" " + (place.location && place.location.city) + " " + (place.name && place.name)}
            </div>
          )}
        </div>
        <div
          className={`text-4xl animate-bounce cursor-pointer transition ${
            currentData > startTime && "hidden"
          }`}>
          <div onClick={() => {setIsOpen(!isOpen)}} className={`${isOpen ? "rotate-180" : "rotate-0"} transition text-red-700`}>
          <MdExpandMore />
          </div>
        </div>
        <div className={`${isOpen ? "flex" : "hidden"} flex-col`}>
          <div className="text-center">
            {description.split("\n").map((line, index) => {
              return(
                <div key={index}>{line}</div>
              )
            })}
            </div>
          <a
            className=" bg-red-700 text-white rounded-xl shadow-sm text-center p-4 mt-5 uppercase font-bold hover:shadow-md transition"
            href={"https://www.facebook.com/event/" + id}
            target="_blank">
            zainteresowany
          </a>
        </div>
      </div>
    </>
  );
};
