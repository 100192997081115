import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header.jsx";
import Navbar from "./components/Navbar.jsx";
import FBevent from "./components/FBevent.jsx";
import Gallery from "./components/Gallery.jsx";
import Kontakt from "./components/Kontakt.jsx";
import image1 from "./assets/img/gallery/1684313994904.jpeg";
import image2 from "./assets/img/marcin.webp";

function App() {
  return (
    <>
      <div>
        <Navbar />
      </div>
      <Header />
      <div
        id="onas"
        className="flex container mx-auto text-5xl font-serpentine my-7 pt-20  justify-center">
        <span className="text-red-700">O</span>&nbsp;nas
      </div>

      <div className="flex flex-col lg:flex-row container mx-auto items-center">
        <div className="flex flex-1 flex-col space-y-2 p-5">
          <p className="text-2xl">Upgrade Platform to Edukacja i Ruch</p>
          <p className="text-xl">Pracujemy z człowiekiem!</p>

          <p>
            Zajmujemy się organizacją szkoleń w zakresie szeroko pojętego
            zdrowia i ruchu. Naszą myślą przewodnią jest dawanie możliwości
            każdemu chcącemu podnieść swoje kwalifikacje by mógł to zrobić
            dzięki spotkaniu ze specjalistą w danym zagadnieniu. Pracujemy
            treningowo na terenie Łodzi z osobami chcącymi podnieść swój poziom
            sportowy, pozbyć się bólu i podnieść komfort życia bądź nauczyć się
            nowych umiejętności.
          </p>
        </div>
        <div className="flex flex-1 overflow-hidden p-5 h-[500px] w-full">
          <img src={image2} className="h-full w-full object-cover object-top" />
        </div>
      </div>

      <Gallery />
      <div
        id="edukacja"
        className="flex container mx-auto text-5xl font-serpentine my-7 pt-20 justify-center">
        <span className="text-red-700">E</span>dukacja
      </div>

      <div className="flex flex-col lg:flex-row container mx-auto items-center">
        <div className="flex flex-1 flex-col space-y-2 p-5">
          <p>
            Nasza oferta szkoleniowa daje Ci możliwość zapoznania się z różnymi
            specjalizacjami w kontekście pracy z ciałem. Możesz nauczyć się
            obsługiwać ketle i clubbells, możesz wejść w świat Movementu jak
            również poszerzyć swoje kompetencje z zakresu diagnostyki sportowej
            lub treningu siłowego. Dajemy Ci możliwości, cała reszta jest po
            Twojej stronie.
          </p>
          <p>
            Nasze wydarzenia odbywają się głównie w Łodzi ale już planujemy
            wydarzenia w innych miastach. Ofertę kierujemy przede wszystkim do
            Trenerów Personalnych, Instruktorów, Trenerów przygotowania
            motorycznego oraz wszystkich pasjonatów. Jeżeli jesteś świadomym
            użytkownikiem swojego ciała to również i Ty znajdziesz tu coś dla
            siebie
          </p>
        </div>
        <div className="flex flex-1 overflow-hidden p-5 h-[500px] w-full">
          <img src={image1} className="h-full w-full object-cover" />
        </div>
      </div>

      <FBevent />

      <div
        id="ruch"
        className="flex container mx-auto text-5xl font-serpentine my-7 pt-20 justify-center">
        <span className="text-red-700">R</span>uch
      </div>

      <div className="flex flex-col lg:flex-row container mx-auto items-center">
        <div className="flex flex-1 flex-col space-y-2 p-5">
          <p>
            Naszą pracę opieramy o kompleksowe konsultacje trenerskie i
            współpracę długoterminową. Minimalny czas współpracy to 3 miesiące.
            W koncepcji w której pracujemy uważamy, że nie da się wprowadzić
            trwałych zmian w czasie krótszym lub jest to mało efektywne.
          </p>
          <p>
            Możemy Ci pomóc w kilku aspektach, poniżej możesz sprawdzić czym
            dokładnie się zajmujemy.
          </p>
          <div className="flex flex-col py-4 ps-4">
            <div className="uppercase font-light text-xl mb-2">
              trening rekreacyjny
            </div>
            <div>
              Trenujesz po to aby poprawić swoje zdrowie i sprawność ? Trenuj z
              nami, wiemy jak zrobić to optymalnie. Nauczymy Cię ruchu a później
              nauczymy CIę ćwiczyć ze sztangą czy kettlami ale również pokażemy
              Ci szerokie spektrum możliwości związanych z szeroko pojętym
              treningiem
            </div>
          </div>
          <div className="flex flex-col py-4 ps-4">
            <div className="uppercase font-light text-xl mb-2">
              REHABILITACJA I PREWENCJA URAZÓW
            </div>
            <div>
              Boli Cię bark lub borykasz się z bólem pleców lub chcesz wrócić do
              pełnej sprawności po kontuzji ?Przyjdź, przynieś dokumentacje od
              lekarza lub fizjoterapeuty, ocenimy Twój aktualny stan i
              przygotujemy indywidualny plan naprawczy.
            </div>
          </div>
          <div className="flex flex-col py-4 ps-4">
            <div className="uppercase font-light text-xl mb-2">
              przygotowanie motoryczne
            </div>
            <div>
              Uprawiasz sport który oprócz specyficznych umiejętności wymaga od
              Ciebie również sprawności ciała ? Sprawdzimy Twoje parametry
              sportowe i przygotujemy strategię aby podnieść.
            </div>
          </div>
          <div className="flex flex-col py-4 ps-4">
            <div className="uppercase font-light text-xl mb-2">
              optymalizacja ruchu
            </div>
            <div>
              Podnosisz ciężary, biegasz a może pływasz ? Dla nas to bez
              znaczenia co robisz bo ruch jest jeden. Dokonamy oceny Twojego
              ciała w ruchu i pomożemy Ci go ulepszyć
            </div>
          </div>
          <p>Koszt pierwszej konsultacji trenerskiej 400zl</p>

          <p className="text-xl pt-3">W ramach pierwszej konsultacji:</p>
          <p>
            Zapoznam się z Twoimi potrzebami i celami Wspólnie ustalimy co jest
            potrzebne aby zakładany cel zrealizować
          </p>
          <p> Sprawdzimy co już masz a nad czym jeszcze musimy popracować</p>
          <p> Przedstawię moją strategie działania.</p>
          <p>
            Koszty dalszej współpracy są ustalane indywidualnie po pierwszej
            konsultacji z uwagi na różnorodność potrzeb i celów.
          </p>
        </div>
      </div>
      <div
        id="kontkat"
        className="flex container mx-auto text-5xl font-serpentine my-7 pt-20 justify-center">
        <span className="text-red-700">K</span>ontakt
      </div>
      <Kontakt />
    </>
  );
}

export default App;
