import React, { useState, useEffect } from "react";

const Gallery = () => {
  const images = require.context(
    "../assets/img/gallery",
    false,
    /\.(png|jpe?g|svg|webp)$/
  );

  const imagePaths = images.keys().map(images);

  return (
    <div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
        {imagePaths.map((image, index) => {
          return (
            <div className="shadow-md overflow-hidden grayscale hover:grayscale-0" key={index}>
              <img className="w-full h-[400px] object-cover" src={image} alt={`image${index + 1}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
