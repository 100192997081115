import React from "react";
import video from "../assets/Grafika2Marcin.mp4";

const Header = () => {
  return (
    <div className="sticky w-screen h-screen">
     <div className="relative w-screen h-screen">
      <video
        muted
        autoPlay
        loop
        className="w-full h-full object-cover brightness-50 grayscale contrast-150">
        <source src={video} type="video/mp4" />
      </video>
      <div className="absolute top-1/3 w-full text-center">
        <div className="text-6xl lg:text-9xl font-serpentine font-bold p-3"><span className="text-red-700">U</span>pgrade platform</div>
        <div className="px-3 text-xl lg:text-3xl font-serpentine"><span className="text-red-700">E</span>dukacja i ruch</div>
        <div className="mt-10">
        <a
            className=" bg-red-700 text-white rounded-xl shadow-sm text-center p-4 uppercase font-bold hover:shadow-md transition"
            href={"#edukacja"}>
            zobacz nasze szkolenia
          </a>
      </div>
      </div>
      </div>
    </div>
  );
};

export default Header;
