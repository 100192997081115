import React from "react";
import { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";


import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineInstagram,
  AiFillFacebook
} from "react-icons/ai";

const Navbar = () => {
  const [toggle, setToggle] = useState(true);
  const [submenu, setSubmenu] = useState(true);
  
  return (
    <div className="fixed w-full z-50">
      <div className="container mx-auto justify-between items-center flex text-[20px] py-5 px-5 md:px-0">
      
        <div className="w-[150px]"><img src={logo} width='100%' height='100%'></img>
        </div>
    
        <div className="justify-between items-center flex-1 ml-20 hidden md:flex">
          <ul className="flex space-x-16 cursor-pointer">
            <li>
              <a href="#onas">O nas</a>
            </li>
            <li>
              <a href="#edukacja">Edukacja</a>
            </li>
            <li>
              <a href="#ruch">Ruch</a>
            </li>
            <li>
              <a href="#kontkat">Kontakt</a>
            </li>
          </ul>
          <div className="flex cursor-pointer text-2xl space-x-2">
            <a href="https://www.instagram.com/upgrade.platform" className="hover:scale-110 transition">
              <AiOutlineInstagram />
            </a>
            <a hreff="https://www.facebook.com/upgrade.platform" className="hover:scale-110 transition">
              <AiFillFacebook />
            </a>
          </div>
        </div>
        <div
          className="flex md:hidden cursor-pointer"
          onClick={() => setToggle((toogle) => !toggle)}>
          <div className="text-4xl">{toggle ? <AiOutlineMenu /> : ``}</div>
          <div
            className={`${
              toggle ? "hidden" : "flex"
            } justify-between absolute w-screen h-screen bg-black bg-opacity-90 left-0 top-0 text-left px-5 py-8`}>
            <ul className=" flex flex-col text-3xl space-y-6 cursor-pointer">
              <li>
                <a href="#">strona główna</a>
              </li>
              <li>
                <a href="#onas">o nas</a>
              </li>
              <li>
                <a href="#edukacja" className=" text-red-700">edukacja</a>
              </li>
              <li>
                <a href="#ruch">ruch</a>
              </li>
              <li>
                <a href="#kontkat">kontakt</a>
              </li>
              <li className="flex justify-left text-3xl">
                <a href="#">
                  <AiOutlineInstagram />
                </a>
              </li>
            </ul>
            <div
              className="flex text-4xl"
              onClick={() => setToggle((toogle) => !toggle)}>
              <AiOutlineClose />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
